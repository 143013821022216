<template>
  <b-card class="mx-1 text-center bg-light-primary">
    <h4>Next Queue Number</h4>
    <h1 class="mt-auto font-weight-bolder">
      {{ currentQueueMeta.current_queue +1 || 0 }}
    </h1>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      currentQueue: [],
      currentQueueMeta: {},

      refreshCurrentQueue: null,
    }
  },
  created() {
    this.refreshCurrentQueue = setInterval(() => {
      this.get_current_issue_queue_status() 
    }, 2000)
  },
  destroyed() {
    clearInterval(this.refreshCurrentQueue)
  },
  methods: {
    get_current_issue_queue_status() {
      this.$http
        .get(`/api/help-desks/issues-queues-current`)
        .then(response => {
          this.currentQueue = response.data.output
          this.currentQueueMeta = response.data.meta
        })
        .catch(() => {})
    },
  }
}
</script>

<style>

</style>